import React, { Fragment, useState, useEffect, Component } from "react"
import styled from "styled-components"

import GlobalFonts from "../font/fonts"

import FooterContainer from "../components/FooterContainer"

import topbackground from "../assets/TopPolygon1.svg"
import topbackground2 from "../assets/TopPolygon2.svg"

import vectorBackground from "../assets/VectorBackground.svg"
import backgroundSlide from "../assets/BackgroundSlide.svg"
import wave from "../assets/Wave.svg"
import waveTop from "../assets/WaveTop.svg"
import Button from "react-bootstrap/Button"
// import Face from "../assets/jimmy_headshot.jpeg"
import Face from "../images/bryan.png"
import Clock from "../assets/clock.png"
import Globe from "../assets/white-globe.jpeg"
import {isEmpty} from "lodash"

const handleSumit = event => {
}

var CalendlyInvite = (props) => {

	console.log("CalendlyInvite!!!")
	console.log(props)

	// console.log(props)
	// // const p = props.peo
	// // console.log(p)
	// // console.log(typeof props.email)
	// // console.log(JSON.stringify(props["email"]));

	const email = props.email
  const first_name = props.first_name
  const last_name = props.last_name
  const phoneNumber = props.phoneNumber

  console.log(email)
	console.log(first_name)
	console.log(last_name)
	console.log(phoneNumber)

	const url = `https://calendly.com/bryan-cloudcastles/15min-1?name=${first_name}%20${last_name}&location=${phoneNumber}&email=${email}`

	console.log(url)

  return (
    <div style={{ height: "700px" }}>
      <iframe
        src={url}
        width="100%"
        height="100%"
        frameBorder="0"
      ></iframe>
    </div>
  )
}


const SetupAMeeting = props => {
	const [first_name, setFirst_name] = useState('')
	const [last_name, setLast_Name] = useState('')
	const [email, setEmail] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')


  useEffect(() => {
  	const url = window.location.href
  	const queryString = window.location.search;
  	const urlParams = new URLSearchParams(queryString);
  	const email = urlParams.get('email')
  	const u_first_name = urlParams.get('first_name')
  	const u_last_name = urlParams.get('last_name')
  	const phoneNumber = urlParams.get('phoneNumber')

  	setFirst_name(u_first_name)
  	setLast_Name(u_last_name)
  	setEmail(email)
  	setPhoneNumber(phoneNumber)


		// setObj(["email"]) = email
		// setObj(["first_name"]) = first_name
		// setObj(["last_name"]) = last_name
		// setObj(["phoneNumber"]) = phoneNumber

		// console.log(obj)

  })


	return (
		<>

			<GlobalFonts />
{/*			<TopBackground src={topbackground} />
			<TopBackground2 src={topbackground2} />*/}
			<Hero>
				<Hero1>
					<CalendlyInvite first_name={first_name} last_name={last_name} email={email} phoneNumber={phoneNumber} />

				</Hero1>
				<Hero2>
					<UserImg src={Face} />
					<TitleText>Setup A Meeting With Bryan</TitleText>
						<Hero3>
							<ClockImg src={Clock} />
							<DetailText>15 Min Meeting </DetailText>
						</Hero3>
						<Hero3>
							<ClockImg src={Globe}/>
							<DetailText>America - San Francisco</DetailText>
						</Hero3>
					<DetailText>Learn how you can own and build passive income from vacation real estate</DetailText>
					<ColoredLine/>
					<DetailText>Bryan has been assigned as your Cloud Castles Consultant</DetailText>
					<DetailText2>bryan@cloudcastles.io</DetailText2>
				</Hero2>
			</Hero>
			<FooterDiv>
				<FooterContainer />
			</FooterDiv>

		</>
	)
}

export default SetupAMeeting

const ColoredLine = styled.hr`
            color: white;
            backgroundColor: 'color';
            height: 5px;
            width:150px;
            animation: SubContentAnimations;
					  animation-delay: 0.1s;
					  animation-duration: 3s;
					  animation-fill-mode: forwards;
					  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
`

const Hero = styled.div`
	background:#8bbef3;
  margin:0px;
  max-width: 100%;
  height:100%;
  overflow-x: hidden;

  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin: 10px auto;

  @media only screen and (max-width: 800px) and (min-width: 100px) {
    flex-direction: column-reverse;
    // margin: -70px 50px 50px -20px;
  }
`

const Hero1 = styled.div`
  background:#8bbef3;
  display: flex;
// margin: 100px auto 0px 0px;
// padding 100px auto auto 100px;
	flex-direction:column;
  // justify-content: center;
  // align-content: center;
  margin: auto;
  width:70%;
	height:100%;
  @media only screen and (max-width: 625px) and (min-width: 100px) {
    // margin: 100px auto 50px 100px;
  }  
 
`
const Hero2 = styled.div`
  
  
  margin-top:  auto;
  margin-bottom:  auto;
  margin-left:  50px;
  margin-right:  auto;
  width:60%;
  height:100%;
  // padding 100px 0px 0px 100px;
    flex: 1;
  align-items:space-evenly;
  justify-content: center;
    @media only screen and (max-width: 800px) and (min-width: 401px) {
		margin-left:  110px;
  }
    @media only screen and (max-width: 400px) and (min-width: 100px) {
		align-items:center;
  }
`


const Hero3 = styled.div`
  margin-top:  auto;
  margin-bottom:  auto;
  margin-left:  auto;
  margin-right:  30px;
  display: flex;
  width:100%;
  flex-direction: row;
  // justify-content: center;
  align-items:space-evenly;
`



const TitleText = styled.h1 `
position: relative;
  width: 80%;
  // height: 43.18px;

  font-family: Poppins;
  font-style: bold;
  font-weight: 500;
  font-size: 27.7436px;
  line-height: 151%;
  /* or 25px */

  color: #fafdff;
    animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
	
`
const DetailText = styled.h2`
position: relative;
  width: 100%;
  // height: 43.18px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16.7436px;
  line-height: 151%;
  /* or 25px */

  color: #fafdff;
    animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
`

const DetailText2 = styled.h2`
position: relative;
  width: 439.52px;
  height: 43.18px;
// margin-right: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12.7436px;
  line-height: 151%;
  /* or 25px */

  color: #fafdff;
    animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
`

const TopBackground = styled.img`
	z-index: -6;
	position: absolute;
	left: -24.63%;
	right: 9.75%;
	top: -2.3%;
	bottom: 78.14%;

	background: #8bbef3;
	border-radius: 333px;
	transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
`
const TopBackground2 = styled.img`
	z-index: -6;
	position: absolute;
	left: -14.72%;
	right: 32.8%;
	top: -16.64%;
	bottom: 81.18%;

	background: #8bbef3;
	opacity: 0.05;
	border-radius: 333px;
	transform: matrix(1, -0.1, 0.08, 1, 0, 0);

`
	
const FooterDiv = styled.div`
	// position: relative;
	width: 100%;
	z-index: 3;
	// left: 0px;
	// top: 700px;
`

const UserImg = styled.img`
  margin-left: 0px;
  margin-top: 0px;
  height: 20%;
  width: 20%;
  border-radius: 100%;
    animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  // left: 50px;
  // right: 0px;
  // top: 30px;
  // bottom: 0px;

  background: url(Image.png);
`
const ClockImg = styled.img`
  // margin-left: 0px;
  // display: flex;
  margin-right: 20px;
  height: 5%;
  width: 5%;
   // justify-content:center;
   // align-items:center;
    // margin:auto;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  // border-radius: 100%;
  // left: 50px;
  // right: 0px;
  // top: 30px;
  // bottom: 0px;

  // background: url(Image.png);
  @media only screen and (max-width: 600px) and (min-width: 100px) {
		  // margin-right: 20px;
		  height: 7%;
		  width: 7%;
  }
`